(function() {
	var modalAlvo = localStorage.getItem('modalAlvo');

	if(
		modalAlvo &&
		modalAlvo !== 'undefined' &&
		modalAlvo !== 'null' &&
		modalAlvo !== ''
	) {

		$(modalAlvo).modal('show');

	}

	const menuLinks = document.querySelectorAll('.main__menu > li > a');

	const abrirModal = document.querySelectorAll('.abrirModal');

	menuLinks.forEach($link => {

		$link.addEventListener('click', e => {
			localStorage.setItem('modalAlvo', $link.getAttribute('data-modal-trigger'));
		});

	});

	abrirModal.forEach(el => {


		el.addEventListener('click', e => {
			localStorage.setItem('modalAlvo', el.getAttribute('data-modal-trigger'));
		});
	})

})();
