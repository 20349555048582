/*

	Para poder fazer o parallax do conteúdo, add a classe ".parallax-content" á seção pai,
	e no css do alvo da animação coloque a seguinte propriedade:

	<div class="section parallax-content">
		<div class="inner">...</div>
	</div>

	<style lang="scss">
		.section {
			.inner{
				transform: translateY( var(--parallax-y) );
			}
		}
	</style>

*/

var parallaxContent = (function() {
	var containers 	= $('.parallax-content');

	if(containers.length > 0) {

		containers.each(function(index, el) {
			var offset = $(this).data('offset') || '.mobile-controls';
			var $this  = $(this);

			if(typeof offset === 'string') {
				offset = document.querySelector(offset).getBoundingClientRect().height;
			}

			verify();

			function verify () {
				var rect 	= el.getBoundingClientRect().top;
				var top		= rect - offset;

				if(top <= 0) {
					$this.css({
						'--parallax-y' : `${Math.abs(parseInt(top * 0.2))}px`
					});
				} else {
					$this.css({
						'--parallax-y' : '0px'
					});
				}

				requestAnimationFrame(verify);
			}
		});

	}
})();
