(function() {
	var observer = new IntersectionObserver(function(entries) {
		$(entries).each(function(index, entry) {
			var $this = $(entry.target);
			var countTo = $this.attr('data-count') || $this.text().replace('.','');

			if(!$this.attr('data-count')) {
				$this.attr('data-count', countTo);
			}

			$this.text(0);

			if(entry.isIntersecting) {

				$({countNum: $this.text()}).animate({
					countNum: countTo
				}, {
					duration : 2000,
					easing : 'linear',
					step : function() {
						var txt = Math.floor(this.countNum)
						$this.text(txt.toLocaleString());
						$this.removeClass('counter');
					},
					complete : function () {
						$this.text(this.countNum.toLocaleString());

						observer.unobserve(entry.target);
					}
				});
			}else {
				$this.text(0);
			}

		});
	});

	stalker();

	
	function stalker() {
		$('.counter:not(.counter-ready)').each(function(index, esteElemento) {

			observer.observe(esteElemento);

			$(this).addClass('counter-ready');

		});

		// requestAnimationFrame(stalker);
	}
})();