(function() {
	var observer;

	check();

	if(window.MutationObserver){

		observer = new MutationObserver(function (mutations) {
			for(var i=0; i<mutations.length; i++) {
				var mutation = mutations[i];
				var children = mutation.addedNodes;

				for(var c=0;c<children.length;c++) {
					var child = children[c];
					var tagName = child.tagName;

					if(tagName === 'LAZY-IMAGE' || tagName === 'LAZYIMAGE') {
						console.log('Você adicionou uma nova '+tagName.toLowerCase())
						LazyImage(child);
					}
				}
			}
		});

		observer.observe(
			document.body,
			{
				childList: true
			}
		);
	}else {
		setInterval(check, (1000/60));
	}


	function check() {
		$('lazyimage, lazy-image').each(function() {
			LazyImage(this)
		});
	}

	function LazyImage($el) {
		var attributes = $el.attributes;
		var img = document.createElement('img');

		// Verificando se existe os atributos width e height
		hasAttribute('height');
		hasAttribute('width');

		// Printando o Elemento na tela
		createIMG();

		function hasAttribute(attr) {
			if(!$el.hasAttribute(attr)) {
				var attribute = $el.style.getPropertyValue('--'+attr);

				if(attribute) {
					return $el.setAttribute(attr, attribute.trim());
				}
			}
		}

		function createIMG() {
			var img = document.createElement('img');

			img.setAttribute('loading', 'lazy')

			for(var i=0;i<attributes.length;i++) {
				var attr = attributes[i];

				if(attr.name && attr.value) {
					img.setAttribute(attr.name, attr.value);
				}
			}

			$($el).after(img)
			$($el).remove()
		}
	}

})();
